import { QuestionType } from "modules/questions/question.types";
import { TbSquareCheck, TbCircleDot, TbWriting } from "react-icons/tb";

export const questionTypeDisplayText = (questionType: QuestionType) => {
  switch (questionType) {
    case QuestionType.SHORT_ANSWER:
      return "Short Answer";
    case QuestionType.MULTIPLE_CHOICE:
      return "Multiple Choice";
    case QuestionType.SELECT_ALL:
      return "Select All";
  }
};

export const QuestionTypeIcon = ({
  type,
  color,
}: {
  type: QuestionType;
  color?: string;
}) => {
  switch (type) {
    case QuestionType.SHORT_ANSWER:
      return <TbWriting color={color} />;
    case QuestionType.MULTIPLE_CHOICE:
      return <TbCircleDot color={color} />;
    case QuestionType.SELECT_ALL:
      return <TbSquareCheck color={color} />;
  }
};
